<template>
  <div id="profile-page">
    <!-- My Details -->
    <vx-card class="mt-base">
      <ul class="page-suggestions-list">
        <li class="page-suggestion flex items-center mb-4" v-for="page in myDetails" :key="page.index">
          <div class="mr-3">
            <img v-if="activeUserInfo.photoURL" key="onlineImg" :src="activeUserInfo.photoURL" alt="user-img" width="40" height="40" class="rounded-full shadow-md cursor-pointer block" />
          </div>
          <div class="leading-tight">
            <p class="font-medium">{{ page.title }}</p>
            <span class="text-xs">{{ page.description }}</span>
          </div>
          <div class="ml-auto">
            <div class="flex">
              <feather-icon icon="ChevronRightIcon" svgClasses="h-4 w-4" class="mr-2 cursor-pointer" @click="$router.push('/super-admin/profile')"></feather-icon>
            </div>
          </div>
        </li>
      </ul>
    </vx-card>

    <!-- Fresh clinics management -->
    <vx-card title="Fresh Clinics Management" class="mt-base">
      <ul class="page-suggestions-list">
        <li class="page-suggestion flex items-center mb-4">
          <div class="leading-tight">
            <p class="font-medium">Admin Accounts</p>
            <span class="text-xs">Manage all admin accounts</span>
          </div>
          <div class="ml-auto">
            <div class="flex">
              <feather-icon icon="ChevronRightIcon" svgClasses="h-4 w-4" class="mr-2 cursor-pointer" @click="$router.push('/super-admin/admins')"></feather-icon>
            </div>
          </div>
        </li>
      </ul>

      <ul class="page-suggestions-list">
        <li class="page-suggestion flex items-center mb-4">
          <div class="leading-tight">
            <p class="font-medium">Email Templates</p>
            <span class="text-xs">Manage fresh clinics entire email template library</span>
          </div>
          <div class="ml-auto">
            <div class="flex">
              <feather-icon icon="ChevronRightIcon" svgClasses="h-4 w-4" class="mr-2 cursor-pointer" @click="$router.push('/super-admin/email-templates')"></feather-icon>
            </div>
          </div>
        </li>
      </ul>

      <ul class="page-suggestions-list">
        <li class="page-suggestion flex items-center mb-4">
          <div class="leading-tight">
            <p class="font-medium">Page Management</p>
            <span class="text-xs">Manage in app pages</span>
          </div>
          <div class="ml-auto">
            <div class="flex">
              <feather-icon icon="ChevronRightIcon" svgClasses="h-4 w-4" class="mr-2 cursor-pointer" @click="$router.push('/super-admin/pages')"></feather-icon>
            </div>
          </div>
        </li>
      </ul>

      <ul class="page-suggestions-list">
        <li class="page-suggestion flex items-center mb-4">
          <div class="leading-tight">
            <p class="font-medium">Consent Form Questions'</p>
            <span class="text-xs">Manage consent form</span>
          </div>
          <div class="ml-auto">
            <div class="flex">
              <feather-icon icon="ChevronRightIcon" svgClasses="h-4 w-4" class="mr-2 cursor-pointer" @click="$router.push('/super-admin/questionnaire')"></feather-icon>
            </div>
          </div>
        </li>
      </ul>

      <ul class="page-suggestions-list">
        <li class="page-suggestion flex items-center mb-4">
          <div class="leading-tight">
            <p class="font-medium">Doctor Missed Call Settings</p>
            <span class="text-xs">Manage notification messages and time interval between calls</span>
          </div>
          <div class="ml-auto">
            <div class="flex">
              <feather-icon icon="ChevronRightIcon" svgClasses="h-4 w-4" class="mr-2 cursor-pointer" @click="$router.push('/super-admin/doctor-missedcall-settings')"></feather-icon>
            </div>
          </div>
        </li>
      </ul>

<ul class="page-suggestions-list">
        <li class="page-suggestion flex items-center mb-4">
          <div class="leading-tight">
            <p class="font-medium">Hubspot Webhook Settings</p>
            <span class="text-xs">Manage automated webhook settings for hubspot integration</span>
          </div>
          <div class="ml-auto">
            <div class="flex">
              <feather-icon icon="ChevronRightIcon" svgClasses="h-4 w-4" class="mr-2 cursor-pointer" @click="$router.push('/super-admin/hubspot-webhook-settings')"></feather-icon>
            </div>
          </div>
        </li>
      </ul>

      <!-- <ul class="page-suggestions-list">
         <li class="page-suggestion flex items-center mb-4">
           <div class="leading-tight">
             <p class="font-medium">Fresh Clinics Users</p>
             <span class="text-xs">Manage all fresh clinics users</span>
           </div>
           <div class="ml-auto">
             <div class="flex">
               <feather-icon icon="ChevronRightIcon" svgClasses="h-4 w-4" class="mr-2 cursor-pointer" @click="$router.push({name:'app-user'})"></feather-icon>
             </div>
           </div>
         </li>
       </ul>-->
    </vx-card>
    <!-- Setting -->
    <vx-card title="Settings" class="mt-base">
      <ul class="page-suggestions-list">
        <li class="page-suggestion flex items-center mb-4" v-for="page in settings" :key="page.index">
          <div class="leading-tight">
            <p class="font-medium">{{ page.title }}</p>
            <span class="text-xs">{{ page.description }}</span>
          </div>
          <div class="ml-auto">
            <div class="flex">
              <feather-icon icon="ChevronRightIcon" svgClasses="h-4 w-4" class="mr-2 cursor-pointer" @click="$router.push('/super-admin/settings')"></feather-icon>
            </div>
          </div>
        </li>
      </ul>
    </vx-card>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        isNavOpen: false,
        userPoll: '',
        wasSidebarOpen: null,
        myDetails: [
          {title: 'My Details', description: 'Manage your account details'}
        ],
        settings: [
          { title: 'Settings', description: 'Control app settings'}
        ],
      }
    },
    computed: {
      activeUserInfo() {
        return this.$store.state.AppActiveUser
      }
    },
    methods: {
      loadContent() {
        this.$vs.loading({
          background: this.backgroundLoading,
          color: this.colorLoading,
          container: "#button-load-more-posts",
          scale: 0.45
        })
        setTimeout(() => {
          this.$vs.loading.close("#button-load-more-posts > .con-vs-loading")
        }, 3000);
      },
    },

    mounted() {
      this.wasSidebarOpen = this.$store.state.reduceButton;
      this.$store.commit('TOGGLE_REDUCE_BUTTON', true)
    },
    beforeDestroy() {
      if (!this.wasSidebarOpen) this.$store.commit('TOGGLE_REDUCE_BUTTON', false)
    }
  }

</script>


<style lang="scss">
  @import "@/assets/scss/vuexy/pages/profile.scss";
</style>
